import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"


import ImgAhorroProyectoComun from "../images/productos/ahorro-proyecto-comun.gif"
import ImgRedondea from "../images/productos/redondea.gif"
import ImgAhorroPorCategoria from "../images/productos/ahorro-por-categoria.gif"
import ImgAhorroRecurrente from "../images/productos/ahorro-recurrente.png"


export const StyledText = styled.div`
  display: inline-block;
  max-width: 32rem;
`

export const StyledHeading = styled.h2`
  line-height: 1.125em;
  letter-spacing: -0.025em;
  color: rgb(25, 28, 31);
  font-size: 2.5rem;
  margin: 0px;

  @media (min-width: 1081px) {
    font-size: 3rem;
  }
`

export const StyledImage = styled.div`
  @media (max-width: 1080px) {
    margin-top: 6rem;
    max-height: 32rem;
  }
`

export const StyledAnimation = styled.div`
  margin: 0px auto;
  box-sizing: border-box;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const StyledSlide = styled.div`
  padding: 0px 3rem;
  margin: auto;
  max-width: 86rem;
  min-height: 100vh;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  flex-direction: row;

  @media (max-width: 519px) {
    padding: 0px 1.5rem;
    margin: 8.25rem 0px 12rem;
    height: auto;
    min-height: 0px;
    max-height: none;
  }

  @media (max-width: 1080px) {
    margin: 70px 0px;
    flex-direction: column-reverse;
    -moz-box-pack: center !important;
    justify-content: center !important;
    -moz-box-align: center !important;
    align-items: center !important;
    max-height: none;

    &:first-child {
      margin-top: auto;
    }

    & > div:first-child {
      margin-right: 0px;
    }

    & h2,
    & p {
      text-align: center;
    }
  }

  @media (min-width: 1081px) {
    ${ props => props.reverse ? "flex-direction: row-reverse" : "" };

    & ${StyledText} {
      ${ props => props.reverse ? "" : "margin-right: 4rem" };
    }

    & ${StyledImage} {
      ${ props => props.reverse ? "margin-right: 4rem" : "" };
    }
  }
`


const MisAhorrosPage = () => (
  <Layout>
    <SEO title="Mis ahorros" />
    <StyledSlide>
      <StyledText>
        <StyledHeading>Ahorros por categorías</StyledHeading>
        <p>
          ¿Planeas viajar? ¿Quieres ahorrar? ¿Tienes algunas deudas?<br />
          <br />
          Crea una alcancía para cada meta.<br />
          Sólo dale un nombre y elige la cantidad que quieres ahorrar.<br />
          Nosotros nos encargamos del resto.
        </p>
      </StyledText>
      <StyledImage>
        <StyledAnimation>
          <img src={ImgAhorroPorCategoria} alt="" />
        </StyledAnimation>
      </StyledImage>
    </StyledSlide>
    <StyledSlide reverse={true}>
      <StyledText>
        <StyledHeading>Ahorra tus moneditas</StyledHeading>
        <p>
          Para cada una de tus compras con la tarjeta KKO, el redondeo se guardará automáticamente en tus ahorros.<br />
          <br />
          Elige en cuál de tus alcancías se va acumular.
        </p>
      </StyledText>
      <StyledImage>
        <StyledAnimation>
          <img src={ImgRedondea} alt="" />
        </StyledAnimation>
      </StyledImage>
    </StyledSlide>
    <StyledSlide>
      <StyledText>
        <StyledHeading>Ahorros recurrentes</StyledHeading>
        <p>
          ¿Quieres ahorrar dinero más rápido?<br />
          <br />
          Programa una meta específica o una cantidad y su alcance.
        </p>
      </StyledText>
      <StyledImage>
        <StyledAnimation>
          <img src={ImgAhorroRecurrente} alt="" />
        </StyledAnimation>
      </StyledImage>
    </StyledSlide>
    <StyledSlide reverse={true}>
      <StyledText>
        <StyledHeading>Proyecto común</StyledHeading>
        <p>
          ¿Tú y tus amigos quieren irse de vacaciones?<br />
          Crea un proyecto común, agrega amigos y todos podrán aportar.<br />
          <br />
          Desde la app podrán revisar en tiempo real el avance del proyecto y les avisaremos cada vez que alguien haga un abono.
        </p>
      </StyledText>
      <StyledImage>
        <StyledAnimation>
          <img src={ImgAhorroProyectoComun} alt="" />
        </StyledAnimation>
      </StyledImage>
    </StyledSlide>
  </Layout>
)

export default MisAhorrosPage
